/* import { useState } from 'react'; */
import Popover from 'react-bootstrap/Popover';
import { CloseButton } from 'react-bootstrap';
import "./pop-over.css"
/* import InfoModal from '../modal/modal'; */

const PopoverInsuranceInfo = (props) => {
    const {body} = props;
    const handleModal = () =>{
        document.body.click()
        props.showModal()
    }
    return(
            <Popover id="popover">
                <CloseButton  onClick={() => document.body.click()} />
                <Popover.Body>
                    {body}
                    <br/>
                </Popover.Body>
            </Popover>
)    
}
export default PopoverInsuranceInfo;