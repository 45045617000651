import { useState } from "react";
import { Accordion } from "react-bootstrap";

export default function TestimonyParent(props){
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    window.addEventListener("resize",()=> setScreenSize(window.innerWidth));

    return (screenSize>=567)?
    <div className='testimonials'>
        {props.testimonies.map(testimonie => testimonie)}
    </div>

    :<Accordion flush /* defaultActiveKey={props.num} */ className="testimonials">
        {props.testimonies.map(testimonie => testimonie)}
    </Accordion>






}