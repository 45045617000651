import { useRef, useState, useEffect} from "react";
import { Button, Col, Container, Row, Form, ProgressBar, OverlayTrigger, Alert, Image } from 'react-bootstrap';
import "./enterprise-form.css";
import validator from 'validator';
import { Modal } from "react-bootstrap";
import PopoverInsuranceInfo from "../pop-over/pop-over";
import emailjs from '@emailjs/browser';
import loader from "../../assets/images/circles.svg";
import formSentSucessImage from "../../assets/images/form-sent-success.svg"

export default function EnterpriseContactForm({titleSection})
{
 useEffect(()=>{      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
 },[]) 
  const [completeForm, setCompleteForm] = useState(false);
  const handleCompleteForm = () => setCompleteForm(true);
  const [show, setShow] = useState(false);  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const propsPopOverDeduductible = {body : "Participación del asegurado en la pérdida ocasionada por el siniestro antes de que el seguro médico comience a pagar. Por lo general, los seguros con deducibles más altos tienen primas más bajas.", idModal:"definitions", showModal: handleShow};
  const propsPopOverHospitalCoverage = {body : "Agrupación de hospitales a los que se tendrá acceso conforme a la póliza contratada.", idModal:"definitions", showModal: handleShow};
  const propsPopOverCoinsurace  = {body : "Porcentaje que corresponde pagar al asegurado calculado sobre la diferencia de la pérdida ocasionada por el siniestro menos el deducible ya pagado.", idModal:"definitions", showModal: handleShow};
  const popOverCoinsurace = PopoverInsuranceInfo(propsPopOverCoinsurace);
  const popOverHospitalCoverage = PopoverInsuranceInfo(propsPopOverHospitalCoverage)
  const popOverDeductible = PopoverInsuranceInfo(propsPopOverDeduductible)
  const [form, setForm] = useState({});
  const [formSentSucces, setFormSentSuccess] = useState({status : null , message : ""});
  const [loading, setLoading] = useState();
  const [process, setProcess] = useState(33);
  const [policyActive, setPolicyActive] = useState(true)
  const refForm = useRef();
  const refNextStepBtn = useRef();
  const validateCurrentFormValues = (idCurrent) =>
  {
    let $formValues = refForm.current.querySelectorAll(`#${idCurrent} input,#${idCurrent} select`);
    let validations = [];
    $formValues.forEach(formField => 
      {
        let validationResult = (!formField.dataset["rule"])? true : validateValue(formField.dataset["rule"], formField.value);
        if(!validationResult)
          formField.classList.add("invalid-input-value");
        validations.push(validationResult);
      });
    return !validations.some(v => v === false);
  }
  const validateValue =(validationType, value) =>{

    const telephonevalidation = /^\+?[0-9]{10,13}$/

    switch (validationType) {
      case "alphanumeric":
        return validator.isAlphanumeric(value, ['es-ES'], {'ignore': ' _-'})
      case "numeric":
        return validator.isNumeric(value, {no_symbols: true})
      
      case "alpha":
        return validator.isAlpha(value, ['es-ES'], {'ignore': ' _-'})

      case "email":
        return validator.isEmail(value)
      case "zip":
        return validator.isPostalCode(value, "MX")
      case "text":
        return value.toString().length > 0
      case "optional-telephone":
        return value.toString().length === 0 || (telephonevalidation.test(value));
      case "employeesnumber":
        return (validator.isNumeric(value)? 
                (parseInt(value,10)>=5)
                ?true
                  :false 
                :false)
      default:
        return false
    }
  }

  const replaceAvailableForm =(prevAvailableId, newAvailableId) =>{
    //Is we want to go to the same page we return same page
    if(prevAvailableId === newAvailableId)
      return;
    let prevForm = refForm.current.querySelector(prevAvailableId);
    let prevFormProgress = parseInt(prevForm.dataset["progress"])
    let currentForm = refForm.current.querySelector(newAvailableId);
    let currentFormProgress = parseInt(currentForm.dataset["progress"])
    //if we want to go next step first we want to validatate current form values otherwise we dont need it
    if(currentFormProgress > prevFormProgress)
    {
      if(!validateCurrentFormValues(prevAvailableId.replace("#", "")))
        return;  
    }
    // set proccess value to change progress bar behavior
    setProcess(currentFormProgress)
    currentForm.classList.replace("form-disabled","form-available")
    refForm.current.querySelector(prevAvailableId).classList.replace("form-available","form-disabled");

  }
  const checkIfAnyFieldOfCurrentIsEmpty  = () =>{
    /* When has not been rendered */
    if(!refForm.current)
      return false;
    
    let fieldstoignore = ["enterprise_current_insurance_company","enterprise_current_broker", "enterprise_current_policy_number"]
    let currentFormFields = refForm.current.querySelectorAll(".form-available input, .form-available select");
    let fieldsNames = Array.from(currentFormFields).filter(el=> el.dataset["rule"] && el.dataset["rule"] !== "optional-telephone"
    && !fieldstoignore.includes(el.name))
    .map(e => e.name);
    let formKeys = Object.keys(form);
    let isAnyEmptyField = fieldsNames.every(v => formKeys.includes(v))

    return isAnyEmptyField;
  }
  const changeToNextForm = (e) =>{
    e.preventDefault()
    let available = refForm.current.querySelector(".form-available");
    switch (available.id) {
      case "enterprise-info":
        if(validateCurrentFormValues("enterprise-info"))
          replaceAvailableForm("#enterprise-info", "#contact-enterprise");
        break;
      case "contact-enterprise":
        if(validateCurrentFormValues("contact-enterprise"))
          replaceAvailableForm("#contact-enterprise", "#enterprise-insurance-info")
        break;
      case "enterprise-insurance-info":
          if(validateCurrentFormValues("enterprise-insurance-info"))
            {
              replaceAvailableForm("#enterprise-insurance-info","#contact-accept")
              handleCompleteForm();
            }
        break
      default:
        console.error("Failed at change current form")
    }
  }
  const changeToPrevForm = (e) =>{
    e.preventDefault();
    let available = refForm.current.querySelector(".form-available");
    switch (available.id) {
      case "enterprise-info":
        break;
      case "contact-enterprise":
          replaceAvailableForm("#contact-enterprise", "#enterprise-info")
        break;
      case "enterprise-insurance-info":
        replaceAvailableForm("#enterprise-insurance-info", "#contact-enterprise")
        break;
      default:
        break
    }
  }
  const hasPreviousPolicies = (e) => {
    if(e.target.value === "Sí")
        setPolicyActive(true)
    else{
      setForm({
        ...form,
        [e.target.name] : e.target.value,
        enterprise_current_insurance_company : null,
        enterprise_current_broker : null,
        enterprise_current_policy_number : null
      })
      setPolicyActive(false)
    }
  }
  const handleClickNavForm = e => {
    let currentForm = refForm.current.querySelector(".form-available")
    replaceAvailableForm(`#${currentForm.id}`, e.target.dataset["formid"] || e.target.parentNode.dataset["formid"])
  }
  const handleChange = e =>{
    e.target.classList.remove("invalid-input-value");
    if(e.target.type === "checkbox" && !e.target.checked)
    {
      setForm({
        ...form,
         [e.target.name] : "no"
      })
      return;
    }
    if(e.target.type === "checkbox" && e.target.checked)
    {
      setForm({
        ...form,
         [e.target.name] : "si"
      })
      return;
    }
    
    //cause this target executes its own event (has PreviousPolcies)
    if(e.target.name === "enterpriseHasActiveInsurancePolicy")
      return
    setForm({
      ...form,
       [e.target.name] : e.target.value
    })
  }
  const handleSubmit = async (e) =>
  {
    e.preventDefault();
    let available = refForm.current.querySelector(".form-available");
    if(available.id !== "contact-accept")
      return 
    if(loading)
      return;
    if(!validateCurrentFormValues("contact-accept"))
      return;
    try{
      await sendForm(form);
    }
    catch(e)
    {
      setFormSentSuccess({status : "danger", message : e.message})
    }
    finally{
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    
    
  }
  const sendForm = async (form) => 
  {

    setLoading(true);
    emailjs.sendForm(`${window.MAIL_SERVICE_ID}`, `${window.MAIL_ENTERPRISE_TEMPLATE_ID}`, refForm.current, `${window.MAIL_USER_ID}`)
      .then((result) => {
        console.log(result.text);
        setLoading(false);
      }, (error) => {
        setFormSentSuccess({status : "danger" , message : "Error al enviar correo"})
      });
    let res = await fetch(`${window.URL_ENTERPRISES_FORM}`, {
      method : "POST",
      headers : {'Content-Type': 'application/json'}, 
      body : JSON.stringify({content : JSON.stringify(form)})})
    if(!res.ok){
      setLoading(false);
      
      throw new Error(`Error al enviar el formulario${"."||" : "+res.statusText}Status ${res.status}`)
    }
    setLoading(false);
    refForm.current.reset();
    setFormSentSuccess({status : "success", message : "El formulario se envio correctamente, pronto te contactaremos :)"})
  }


  return <div className="form-enterprises-container">
    {
      (formSentSucces.status === null)
      ?
      <Container className='enterprise-contact-full-form'>
          {(!completeForm)?
          <>
            {titleSection}
            <Row xs={{ cols: 3 }} sm={{ cols: 4 }} md={{ cols: 4 }} className="enterprise-progress-buttons" onClick={handleClickNavForm} lg={{ cols: 4 }} >        
              <button data-formid="#enterprise-info" className={(process>=33?"flush":"")}><span className="step">1</span> Empresa</button>
              <button data-formid="#contact-enterprise" className={(process>=66?"flush":"")}><span className="step">2</span> Contacto</button>
              <button data-formid="#enterprise-insurance-info" className={(process>=99?"flush":"")}><span className="step">3</span> Seguro</button>
            </Row>
            <Row>
              <ProgressBar id="progress-bar" now={process} />
            </Row>
          </>
          :<>
          {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
          }
            <Row  className="contact-header-title">
              <div className="thanks-title"><h2>¡Gracias!</h2></div>
              <div >
                <p>En breve nuestro equipo se pondrá en contacto contigo para continuar el proceso de registro y cotización.</p>
                <p>¿Deseas obtener la información vía correo electrónico, whatsApp o ambas?</p>
              </div>
            </Row>
          </>}
    
          <Form ref={refForm} id="contact-enterprises" onChange={handleChange} onSubmit={handleSubmit}>
            <Row id="enterprise-info" data-progress={33} className="form-available" xs={{ cols: 1 }} md={{ cols: 1 }} lg={{ cols: 2 }}>
              <Col>
                <Form.Group>
                  <Form.Label>Nombre de la empresa</Form.Label>
                  <Form.Control data-rule="text" type="text" placeholder="Nombre de la empresa" name="enterprise_name" />
                  <div className="alert-data">*Este campo tiene que ser un texto</div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Código Postal de la Empresa</Form.Label>
                  <Form.Control data-rule="zip" type="text"  placeholder="CP. 00000" name="enterprise_zipcode" />
                  <div className="alert-data">*Código postal inválido.</div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Giro de la empresa</Form.Label>
                  <Form.Control data-rule="alpha" type="text" placeholder="Ej.Industrial,comercial,de servicios" name="enterprise_type" />
                  <div className="alert-data">*Este campo solamente permite letras</div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Número de empleados</Form.Label>
                  <Form.Control data-rule="employeesnumber" type="number" placeholder="Número de empleados" name="emplyes_number" />
                  <div className="alert-data">*Campo obligatorio. Empleados minimos 5</div>
                </Form.Group>
              </Col>
              
            </Row>
            <Row id="contact-enterprise" data-progress={66} className="form-disabled" xs={{ cols: 1 }} md={{ cols: 1 }} lg={{ cols: 2 }}>
              <Col>
                <Form.Group>
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control data-rule="alpha" maxLength={50} type="text" placeholder="Nombre" name="contact_name" />
                  <div className="alert-data">*Este campo solamente permite letras</div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Apellidos</Form.Label>
                  <Form.Control data-rule="alpha" type="text" maxLength={80} placeholder="Apellidos" name="contact_last_name" />
                  <div className="alert-data">*Este campo solamente permite letras</div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Puesto</Form.Label>
                  <Form.Control data-rule="alpha" type="text" maxLength={50} placeholder="Ej.Gerente,Administrador,Contador..." name="contact_enterprise_position" />
                  <div className="alert-data">*Este campo solamente permite letras</div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Correo electrónico</Form.Label>
                  <Form.Control data-rule="email" type="email" maxLength={80} placeholder="Correo electrónico" name="enterprise_contact_email" />
                  <div className="alert-data">*Este valor no coincide con un correo electrónico</div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Teléfono Celular (opcional)</Form.Label>
                  <Form.Control data-rule="optional-telephone" type="tel" maxLength={14} placeholder="000 000 0000" name="contact_telephone" />
                  <div className="alert-data">*Este valor no coincide con un número telefonico, máximo 13 dígitos.</div>
                </Form.Group>
              </Col>
            </Row>
            <Row id="enterprise-insurance-info" data-progress={100} className="form-disabled enterprise-insurance-info-container" xs={{ cols: 1 }} md={{ cols: 1 }} lg={{ cols: 2 }}>
              <Col>
                  <Form.Group>
                      <Form.Label>¿La empresa cuenta actualmente con alguna póliza de seguro?</Form.Label>
                      <Form.Select onChange={hasPreviousPolicies} name="enterprise_has_insurance_policy">
                      <option value={"Sí"}>Sí</option>
                      <option value={"No"}>No</option>
                      </Form.Select>
                  </Form.Group>
              </Col>
              {
                (policyActive)
                ?<>
                <Col>
                    <Form.Group>
                        <Form.Label>Aseguradora</Form.Label>
                        <Form.Control data-rule="alpha" type="text" maxLength={80}  placeholder="Nombre de la aseguradora" name="enterprise_current_insurance_company" />
                        <div className="alert-data">*Campo obligatorio.</div>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Broker</Form.Label>
                        <Form.Control data-rule="alpha" type="text" maxLength={80} placeholder="Institución o Individuo" name="enterprise_current_broker" />
                        <div className="alert-data">*Campo obligatorio.</div>
                    </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                      <Form.Label>Número de poliza</Form.Label>
                      <Form.Control data-rule="text" type="text" maxLength={80} placeholder="Póliza" name="enterprise_current_policy_number" />
                      <div className="alert-data">*Campo obligatorio.</div>
                  </Form.Group>
                </Col>
                </>
                :<></>
              }
              <Col>
                <Form.Group >
                  <Form.Label>Tipo de póliza de su interés</Form.Label>
                  <Form.Select data-rule="text" name="enterprise_desired_policy_type">
                  <option value="">Elige el tipo de póliza</option>
                    <option default value="accidentes personales">Accidentes personales</option>
                    <option default value="gastos medicos menores">Gastos médicos menores</option>
                    <option default value="gastos medicos mayores">Gastos médicos mayores</option>
                    <option default value="gastos medicos menores mas mayores">Gastos médicos menores más gastos médicos mayores</option>
                  </Form.Select>
                  <div className="alert-data">*Elige una opción por favor.</div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group >
                  <Form.Label>Deducible <OverlayTrigger rootClose trigger="click" placement="top" overlay={popOverDeductible}>
                    <a href="#deductible"><span className="material-symbols-outlined">help</span></a>
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Select data-rule="text" name="enterprised_desired_deductible">
                    <option value="">Elige el monto del deducible</option>
                    <option value="$3,000 - $6,000">$3,000 - $6,000</option>
                    <option value="$6,000 - $12,000">$6,000 - $12,000</option>
                    <option value="$12,000 - $15,000">$12,000 - $15,000</option>
                    <option value="$15,000 - $20,000">$15,000 - $20,000</option>
                    <option value="Más de $20,000">Más de $20,000</option>
                  </Form.Select>
                  <div className="alert-data">*Elige una opción por favor.</div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group >
                  <Form.Label>Coaseguro&nbsp;  
                    <OverlayTrigger rootClose trigger="click" placement="top" overlay={popOverCoinsurace}>
                    <a href="#coaseguro"><span className="material-symbols-outlined">help</span></a>
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Select data-rule="text" name="enterprise_desired_coinsurance">
                    <option value="">Elige el porcentaje de coaseguro</option>
                    <option value="10%">10%</option>
                    <option value="15%">15%</option>
                    <option value="20%">20%</option>
                  </Form.Select>
                  <div className="alert-data">*Elige una opción por favor.</div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group >
                  <Form.Label>Nivel Hospitalario <OverlayTrigger rootClose trigger="click" placement="top" overlay={popOverHospitalCoverage}>
                    <a href="#hospitalcoverage"><span className="material-symbols-outlined">help</span></a></OverlayTrigger></Form.Label>
                  <Form.Select data-rule="text" name="enterprise_desired_hospital_coverage">
                    <option value="">Elige un nivel hospitalario</option>
                    <option value="1. Clínica Patriotismo, Hospital Médica Londres, Hospital Merlos">1 - Ej: Clínica Patriotismo, Hospital Médica Londres, Hospital Merlos</option>
                    <option value="2. Angeles Roma, Angeles Mocel, Centro Médico Dalinde">2 - Ej: Angeles Roma, Angeles Mocel, Centro Médico Dalinde</option>
                    <option value="3. Angeles Acoxpa, Angeles Metropolitano, Médica Sur">3 - Ej: Angeles Acoxpa, Angeles Metropolitano, Médica Sur</option>
                    <option value="4. Ángeles del Pedregal, Hospital ABC Santa Fe">4 - Ej: Ángeles del Pedregal, Hospital ABC Santa Fe</option>
                  </Form.Select>
                  <div className="alert-data">*Elige una opción por favor.</div>
                </Form.Group>
              </Col>
            </Row>
            <Row id="contact-accept" data-progress={101} className="form-disabled contact-accept-container" xs={{ cols: 1 }} md={{ cols: 1 }} lg={{ cols: 2 }}>
              <Col style={{paddingLeft:"1rem"}}>
                  <Form.Check
                  inline 
                  type="checkbox"
                  name ="contact_email"
                  id={`contact-email`}
                  label="Correo eléctronico"

                />
    
                <Form.Check
                  inline
                  type="checkbox"
                  name ="contact_whatsapp"
                  label={`Whatsapp`}
                  id={`contact_whatsapp`}

                />       
              </Col>
            </Row>
            <Modal size="lg" dialogClassName="modalStyles" aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose} animation={true}>
              <Modal.Header closeButton />
              <Modal.Body>
                <div>
                  <strong>Deducible</strong>
                  <p>
                    Participación del asegurado en la pérdida ocasionada por el siniestro antes de que el seguro médico comience a pagar. Por lo general, los seguros con deducibles más altos tienen primas más bajas.
                  </p>
                </div>
                <div>
                  <strong>Coaseguro</strong>
                  <p>
                    Porcentaje que corresponde pagar al asegurado calculado sobre la diferencia de la pérdida ocasionada por el siniestro menos el deducible ya pagado.
                    </p>
                </div>
                <div>
                  <strong>Nivel Hospitalario</strong>
                  <p>
                    Agrupación de hospitales a los que se tendrá acceso conforme a la póliza contratada.              </p>
                </div>
    
    
              </Modal.Body>
            </Modal>
            <Row xs={{ cols: 1 }} sm={{ cols: 1 }} md={{ cols: 2 }} lg={{ cols: 2 }}>
              
              <Col className="control-buttons">
                {
                  (process>33 && !completeForm)
                  ?<Button ref={refNextStepBtn} id="previous-step" type="button" className="btn-lg previous" onClick={e=> changeToPrevForm(e)}>
                    Atras
                  </Button> 
                  : <span></span>
                }
                {
                  (process <101)
                  /* className={`btn-lg next mb-1 ${policyActive?"":""}`} */
                  ?<Button disabled = {!checkIfAnyFieldOfCurrentIsEmpty()}ref={refNextStepBtn} id="next-step" type="button" className={`btn-lg next mb-1 ${policyActive?"":""}`} style={{marginLeft:(process>25)?"1vh":"0"}} onClick={e=> changeToNextForm(e)}>
                    {process === 100?"Enviar" : "Continuar"}
                   </Button>
                  :<Button disabled = {form["contact_email"] !== "si" && form["contact_whatsapp"] !== "si"}ref={refNextStepBtn} id="sendForm" type="submit" className="btn-lg next mb-1">
                    {(!loading)? <span>&nbsp;&nbsp;Finalizar&nbsp;&nbsp;</span>:<><span>Enviando  </span><Image className="loader" src={loader}></Image></>}
                   </Button>
                }
              </Col>
            </Row>
          </Form>
        </Container>
        :
      <Container className='enterprise-contact-full-form' style={{minHeight:"60vh"}}>
          <Row >
            <Col className="at-sent-form-message">
            <div className="success-sent-image">
            {  
                (formSentSucces.status !== "danger")
                ? <a href="/">
                    <Image fluid src ={formSentSucessImage}></Image>
                  </a>

                : <>
                  <span className="message1">Error al procesar tus datos</span>
                  <br />
                  <Alert key="alertAtSent" variant={formSentSucces.status}>
                    {formSentSucces.message}
                    <br></br>
                    <Alert.Link href="/">Regresar a la pagina de inicio</Alert.Link>
                  </Alert>
                  </>
            }
            </div>             
            </Col>
          </Row>
      </Container>


    }
  </div>
}  

EnterpriseContactForm.defaultProps ={
  titleSection : <Row className="new-register">Nuevo Registro de Empresa</Row>
}

