import './plan-card.css';
import { Card } from "react-bootstrap";

export default function PlanCard(props) {
  return (
    <Card className="plan-card" style={{ borderTop: '10px solid ' + props.color }}>
      <div className="header">
        <img src={props.icon} alt="Plan" />
        <h3 className="montserrat-semi-bold-tangaroa-24px">{props.title}</h3>
        <p className="montserrat-medium-tangaroa-16px">
          <span style={{ marginRight: '5px' }} className="montserrat-normal-fiord-10px">Desde</span>
          ${props.price?.toLocaleString('mx', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} MXN
        </p>
      </div>
      <Card.Body>
        <p className="montserrat-semi-bold-tangaroa-14px">Incluye:</p>
        <ul>
          {props.includes?.map((value, index) => <li key ={index} className="montserrat-normal-tangaroa-14px">{value}</li>)}
        </ul>
        <p style={{textAlign: 'center'}} className="montserrat-normal-comet-12px">{props.appliesTo}</p>
        
      </Card.Body>

    </Card>
  );
}
