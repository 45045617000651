import "./footer.css";
import { useState } from "react";
import logo from "../../assets/images/logo-sincero-light.svg";
import rights from "../../assets/images/footer/rights-reserved.svg";
import rightsMobile from "../../assets/images/footer/rights-reserved-mobile.svg";
import logoLinkedin from "../../assets/images/footer/icon-linkedin.svg";
import logoTelephone from "../../assets/images/footer/icon-telephone.svg"
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import register from "../../assets/images/cta-image.svg";
import registerMobile from "../../assets/images/cta-image-mobile.svg";
import { individualurls, enterpriseUrls} from '../Routes';

export default function Footer() {
  const handleClickFooter = (e) =>{
    let element = document.querySelector(e.target.href.split("/").slice(-1)[0]);
    if(element)
      {
        element.scrollIntoView()
      }
    }
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  window.addEventListener("resize",()=> setScreenSize(window.innerWidth));
  let location = useLocation();
  let path = location.pathname;
  let currentRol = Object.values(individualurls).includes(path)
                    ? individualurls 
                    : Object.values(enterpriseUrls).includes(path)
                      ? enterpriseUrls
                      : enterpriseUrls;
  
  return (
    <div className="footer">
      {
        (currentRol.home.includes(path) && currentRol.context === "enterprises")
          ?
        <div className="ready-to-register">
          <div className='register-me'>
          {(screenSize>=567)?
          <>
            <Image fluid={true} src={register} loading= "lazy" alt = "registrate" />
            {/* eslint-disable-next-line */}
            <a href="/registro-empresas"></a>
          </>
          :
          <a href="/registro-empresas">
            <Image fluid={true} src={registerMobile} loading= "lazy" alt = "registrate" />
          </a>
          }
          </div>
        </div>
        : <></>  
      }
      <Container >
        <Row style={{ padding: '20vh 0 50px 0' }} className="footer-links">
          <Col sm="12" md="3" className="logo-container">
            <Image
              fluid
              className="logo-sincero"
              src={logo}
              alt="Logo Sincero"
            />
          </Col>
          {(path === enterpriseUrls.home)
          ?          
          <Col sm="12" md="2">    
              <p className="poppins-normal-white-24px">Empresas</p>        
              <p><Link onClick={handleClickFooter} to={currentRol.plans} className="poppins-normal-white-16px">Planes de salud</Link></p>
              <p><Link to={currentRol.contact} className="poppins-normal-white-16px">Contacto</Link></p>
              <p><Link onClick={handleClickFooter} to={currentRol.faqs} className="poppins-normal-white-16px">FAQ</Link></p> 
          </Col>
          :null}
          <Col sm="12" md="2">
          {(currentRol.context==="individual")
            ?<>    
              <p className="poppins-normal-white-24px">Servicios</p>        
              <p><Link to={currentRol.plans} className="poppins-normal-white-16px">Sincero Vital</Link></p>
              <p><Link to={currentRol.plans} className="poppins-normal-white-16px">Sincero Flex</Link></p>
              <p><Link to={currentRol.plans} className="poppins-normal-white-16px">Sincero Plus</Link></p>
              <p><Link to={currentRol.plans} className="poppins-normal-white-16px">Sincero Total</Link></p>
            </>
            :(currentRol.context==="enterprises")
            ?
            <>    
              <p className="poppins-normal-white-24px">Servicios</p>        
              <p><Link onClick={handleClickFooter} to={currentRol.plans} className="poppins-normal-white-16px">Accidentes Personales</Link></p>
              <p><Link onClick={handleClickFooter} to={currentRol.plans} className="poppins-normal-white-16px">Gastos médicos mayores</Link></p>
              <p><Link onClick={handleClickFooter} to={currentRol.plans} className="poppins-normal-white-16px">Gastos médicos menores</Link></p>
              <p><Link onClick={handleClickFooter} to={currentRol.plans} className="poppins-normal-white-16px">Cobertura Total</Link></p>
            </>
            : <></>
          }
          </Col>
          <Col sm="12" md="2">
          {(currentRol.context==="individual")
            ?<>    
              <p className="poppins-normal-white-24px">Personas</p>        
              <p><Link to={currentRol.plans} className="poppins-normal-white-16px">Planes de salud</Link></p>
              <p><Link to={currentRol.contact} className="poppins-normal-white-16px">Contacto</Link></p>
              <p><Link to={currentRol.faqs} className="poppins-normal-white-16px">FAQ</Link></p>
            </>
            :(currentRol.context==="enterprises")
            ?<>
          {/*           <p className="poppins-normal-white-24px">Empresas</p>        
              <p><Link to={currentRol.plans} className="poppins-normal-white-16px">Planes de salud</Link></p>
              <p><Link to={currentRol.contact} className="poppins-normal-white-16px">Contacto</Link></p>
              <p><Link to={currentRol.faqs} className="poppins-normal-white-16px">FAQ</Link></p> */}
            <p><a href={`${window.PRIVACY_POLICY}`} className="poppins-normal-white-16px">Aviso de privacidad</a></p>
            <p><Link to={currentRol?currentRol.contact || enterpriseUrls.register:enterpriseUrls.contact} className="poppins-normal-white-16px">Contacto</Link></p>
              </>
            : <></>
          }
          </Col>
          
          {
            (currentRol.context==="individual")
            ?<Col sm="12" md="2">
              <p>&nbsp;</p>
              <p><a href={`${window.PRIVACY_POLICY}`} className="poppins-normal-white-16px">Aviso de privacidad</a></p>
              <p><Link to={currentRol?currentRol.contact || enterpriseUrls.register:enterpriseUrls.contact} className="poppins-normal-white-16px">Contacto</Link></p>
             </Col>
            :<></>
          }
          
          {
          (path !== enterpriseUrls.home)
              ?<Col sm ="0" md="2"></Col>
              :null
          }
          
          <Col sm="12" md="3" className="icons-col">
            <div className="icons-container">
              <a href={individualurls.linkedin}>
                <img
                  src={logoLinkedin}
                  alt="Linkedin icon"
                />
              </a>
              <a href={currentRol?currentRol.whatsapp:individualurls.whatsapp}>
              <img
                src={logoTelephone}
                alt="telefono de contacto"
              />
              </a>
            </div>
          </Col>
        </Row>
        <Row style={{ padding: '10px', textAlign: 'center' }}>
          <Col>
          <Image fluid src={(screenSize>567)?rights:rightsMobile} alt="derechos reservados" />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
