import logo from '../../assets/images/logo-sincero.svg';
import { useState} from 'react';
import logoLinkedin from "../../assets/images/footer/icon-linkedin-black.svg";
import logoTelephone from "../../assets/images/footer/icon-whatsapp-black.svg"
import './customnavbar.css';
import {Container, Row, Col, Navbar, Nav, Button , NavDropdown, Offcanvas} from 'react-bootstrap';
import { Link, useLocation} from 'react-router-dom';
import { individualurls, enterpriseUrls, individualroute, enterpriseroute } from '../Routes';

export default function CustomNavbar() {
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }
  const handleClose = (e) => setMenuOpen(false);

  
  const handleCloseLinkHash = (e) => {
      if(!menuOpen)
        return
      setMenuOpen(false);
      setTimeout(() => {
        e.target.click();
      }, 1000);
      return;
  }
  let location = useLocation();
  let path = location.pathname;
  let currentRol = Object.values(individualurls).includes(path)
                    ? individualurls 
                    : Object.values(enterpriseUrls).includes(path)
                      ? enterpriseUrls
                      : enterpriseUrls;
  return (
    <div className='twonavs'>

    <Navbar  expand="xs" className="customer-type-nav">
      <Container className="context-buttons">
        <Link to={enterpriseroute} className={currentRol.context==="enterprises"?"current-page":null} >&nbsp;Sincero&nbsp;Empresas&nbsp;</Link>
        <Link to={individualroute} className={currentRol.context==="individual"?"current-page":null}>&nbsp;Sincero&nbsp;Personas&nbsp;</Link>
      </Container>
    </Navbar>
    <Navbar collapseOnSelect as="nav" expand="lg" className="sincero-navbar">
      <Container>
        <Navbar.Brand href={currentRol?currentRol.home:"/"} className='header-logo'>
          <img src={logo} alt="Logo" />
        </Navbar.Brand>
        <NavDropdown className="header-button-sesion nav2" title="Iniciar sesión" id="collasible-nav-dropdown2">
                <NavDropdown.Item className="enterprise-sesion" href={enterpriseUrls.startSesion}>Empresas</NavDropdown.Item>
                <NavDropdown.Item className="person-sesion" href={individualurls.startSesion}>Personas</NavDropdown.Item>
            </NavDropdown>
        <Navbar.Toggle onClick={toggleMenu} className="custom-toggler" 
        aria-controls="offcanvasNavbar-expand-xs"
        aria-labelledby="offcanvasNavbarLabel-expand-xs" />
        <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-xs`}
              aria-labelledby={`offcanvasNavbarLabel-expand-xs`}
              placement="end"
              restoreFocus={false}
              show = {menuOpen}
              onHide={handleClose}
              >
        
        <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xs`} >
                <img src={logo} alt="Logo"  className="logo-offcanvas"/>
                </Offcanvas.Title>
              </Offcanvas.Header>
        <Offcanvas.Body>      
          <Container className="offcanvas-switch-container mobile">
            <Row>
              <Col className="offcanvas-switch-col">
                <Button variant="light"  className={currentRol.context==="enterprises"?"current-page":"disabled"} >Empresas</Button>
                <Button variant="light"  className={currentRol.context==="individual"?"current-page":"disabled"}>Personas</Button>
              </Col>
            </Row>
          </Container>      
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="header-options">
                <Nav.Link onClick={handleCloseLinkHash} href={currentRol?currentRol.plans:individualurls.plans} className={currentRol.contact?null:"not-available"}>
                  <div>Planes de salud</div>                
                  <button className="mobile plus"></button>
                </Nav.Link>
                <Nav.Link onClick={handleCloseLinkHash} href={currentRol?currentRol.contact:individualurls.contact} className={currentRol.contact?null:"not-available"}>
                  <div>Contáctanos</div>                
                  <button className="mobile plus"></button>
                </Nav.Link>

                <Nav.Link onClick={handleCloseLinkHash} id="faqs-offcanvas" href={currentRol?currentRol.faqs:individualurls.faqs} className={currentRol.faqs?null:"not-available"}>
                  <div>FAQ</div>
                  <button className="mobile plus"></button>
                </Nav.Link>
            </Nav>
            <div className="icons mobile" >
                <a href={currentRol.linkedin}>
                <img
                  src={logoLinkedin}
                  alt="Linkedin icon"
                  
                />
                </a>
                <a href="/home">
                <img
                  src={logoTelephone}
                  alt="telefono de contacto"
                />
                </a>
                
            </div>
            <div className="sesion-buttons">
              <Nav.Link className="btn header-button just-desktop" href={currentRol?currentRol.register:individualurls.register}  style={{minWidth:"200px"}}>
                {currentRol?(currentRol.context === "enterprises")?"Registrar Empresa":"Registrarme" :"Registrar Empresa"}
                </Nav.Link>
                <NavDropdown className="header-button-sesion just-desktop" title="Iniciar sesión" id="collasible-nav-dropdown">
                  <NavDropdown.Item className="enterprise-sesion" href={enterpriseUrls.startSesion}>Empresas</NavDropdown.Item>
                  <NavDropdown.Item className="person-sesion" href={individualurls.startSesion}>Personas</NavDropdown.Item>
              </NavDropdown>
            </div>  
          </Navbar.Collapse>
        </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
    </div>
    
  );
}
