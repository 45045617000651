export default function NotFoundPage() {
  return (
    <div style={{minHeight:"70vh", paddingTop:"25vh",textAlign:"center"}}>
      <h1>¡Esta pagina no existe! 😥</h1>
      <br></br>
      <br></br>
      <h2 style={{color:"#FF3333"}}>404</h2>
      <br></br>
      <br></br>
      <a href="/">Click para regresar a la página principal</a>
    </div>
  )
}