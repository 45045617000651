import "./Benefit.css";
import { Col, Row, Image } from "react-bootstrap";
export default function Benefit({benefitdescription, imageurl, imagealt}){
    return(
        <Col className="con1">
            <Row xs={1} sm={1} lg={1} md={1}>
                <Col className="benefit-image-block">
                    <Image fluid={true} className="benefit-image"  src = {imageurl} alt ={imagealt?imagealt:""} ></Image>
                </Col>
                <Col>
                    <p className="benefit-description">{benefitdescription}</p>
                </Col>
            </Row>
        </Col>
    );

}