import mainimage from "../../assets/images/enterprises-plans/major-medical.png"
import "./MajorMedicalPlan.css"
import { Container, Image } from "react-bootstrap";


export default function GMMPlan(){
    return(
        <div className="container-plans">
            <Container className="major-medical-container">
                <div className="major-medical-plan">
                <div className="image-and-hero">
                    <div className="image">
                        <Image fluid={true} src={mainimage}/>
                    </div>
                    <div className="initial-price-plan">
                    Planes desde $5,000 MXN anuales por asegurado
                    </div>
                    
                    <div className="big-title-plan">
                    Gastos Médicos Mayores Colectivo (GMM) para empresas
                    </div>
                    <div className="plan-text">
                    Que cubre por cada enfermedad o accidente la cantidad que
                    resulte después de descontar el deducible y coaseguro respecitvamente al monto procedente
                    </div>
                </div>
                <div className="plan-features">
                    <div className="coverages-plan">
                    <div className="title-coverages">Coberturas</div>
                        <div>
                            <ul className="coverages-list">
                                <li>Asistencia en viajes.*</li>
                                <li>Emergencia en el extranjero.*</li>
                                <li>Gastos de hospitalización con habitación privada.</li>
                                <li>Gastos de hospitalización con habitación privada.</li>
                                <li>Medicamentos dentro y fuera del hospital.</li>
                                <li>Indemnización por maternidad.</li>
                                <li>Cero deducible por accidente.</li>
                                <li>Traslado en ambulancia terrestre o aérea.</li>
                                <li>Extensión de cobertura a familiares (cónyugue, hijos y padres).*</li>
                                <li>Asistencia dental.*</li>
                                <li>Asistencia funeraria.*</li>
                                <li>Consultas médicas de especialidad a precio especial y 
                                descuentos en laboratorios.*</li>
                            </ul>
                        </div>

                    </div>
                    <div className="plan-details">
                        <div className="title-details">Deducible</div>
                        <div className="desc">Desde $5,000</div>
                        <div className="title-details">Coaseguro</div>
                        <div className="desc">10%</div>
                        <div className="title-details">Número mínimo de asegurados</div>
                        <div className="desc">5 asegurados titulares (empleados)</div>
                    </div>

                </div>
                </div>
        
        
        </Container>
        </div>
    )


}