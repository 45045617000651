import { Container } from "react-bootstrap";
import FormContact from "./form";
import HeadingForm from "./headingform";



export default function Contact(){

    return(
        <Container id="full-personal-form-container" style={{marginBottom: "70px", marginTop: "17vh"}}>
            <HeadingForm/>
            <FormContact/>
        </Container>
    );
}