import { Container } from "react-bootstrap";
import "./headingform.css";

export default function HeadingForm()
{
    return(
        <Container className="heading">
            <span className="greater"> Nos encanta saber de ti,</span>
            <br />
            <span className="smaller"> dejanos tus datos y nos podremos en contacto contigo</span><span className="greater">👋</span>
        </Container>
    )
}