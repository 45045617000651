import { useEffect } from 'react';
import PlanCard from './components/plan-card/plan-card';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import './plans.css';

export default function Plans(){
    useEffect(()=>{      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    },[]) 

    return(
        <Container style={{marginTop: "17vh", padding : "0 auto", marginBottom: "7vh"}}>
            <Row id="plans" className='section6-row'>
                <Col>
                    <p className="montserrat-medium-manatee-20px section4-subtitle">Planes de salud</p>
                    <p className="montserrat-bold-deep-cove-50px section4-title">Cobertura <span className='home-span-blue'>sincero</span></p>
                </Col>
            </Row>
            <Row>
                <Col xs="12" md="6" lg="6" xl="3">
                    <PlanCard
                    icon="https://anima-uploads.s3.amazonaws.com/projects/62f3fa4227ede0977a7b5a2b/releases/62f400c6e2b6fa3dc59b6317/img/sincero-vital@1x.png"
                    color="var(--royal-blue)"
                    title="Sincero Vital"
                    price={4849}
                    includes={[
                        'Check up anual',
                        'Consultas con especialistas',
                        'Orientación a distancia',
                        'Ambulancia',
                        'Estudios de laboratorio',
                        'Terapia física',
                    ]}
                    appliesTo="Aplica para personas de todas las edades."
                    onClick={() => {}}
                    />
                </Col>
                <Col xs="12" md="6" lg="6" xl="3">
                    <PlanCard
                    icon="https://anima-uploads.s3.amazonaws.com/projects/62f3fa4227ede0977a7b5a2b/releases/62f400c6e2b6fa3dc59b6317/img/sincero-flex@1x.png"
                    color="#c751ff"
                    title="Sincero Flex"
                    price={11302}
                    includes={[
                        'Hospitales Selectos en México',
                        'Atención Médica en el Extranjero',
                        'Eliges a tu Médico',
                        'Indemnización por Maternidad',
                        'Suma asegurada',
                        'Reducción de Coaseguro',
                    ]}
                    appliesTo="Aplica para personas de los 0 a los 70 años."
                    onClick={() => { }}
                    />
                </Col>
                <Col xs="12" md="6" lg="6" xl="3">
                    <PlanCard
                    icon="https://anima-uploads.s3.amazonaws.com/projects/62f3fa4227ede0977a7b5a2b/releases/62f400c6e2b6fa3dc59b6317/img/sincero-plus@1x.png"
                    color="var(--picton-blue)"
                    title="Sincero Plus"
                    price={20982}
                    includes={[
                        'Todos los hospitales en México',
                        'Ayuda para Atención en el Extranjero',
                        'Eliges a tu Médico',
                        'Indemnización por Maternidad',
                        'Suma asegurada',
                        'Reducción de Coaseguro',
                    ]}
                    appliesTo="Aplica para personas de los 0 a los 70 años."
                    onClick={() => { }}
                    />
                </Col>
                <Col xs="12" md="6" lg="6" xl="3">
                    <PlanCard
                    icon="https://anima-uploads.s3.amazonaws.com/projects/62f3fa4227ede0977a7b5a2b/releases/62f400c6e2b6fa3dc59b6317/img/sincero-plus@1x.png"
                    color="var(--persimmon)"
                    title="Sincero Total"
                    price={57434}
                    includes={[
                        'Todos los hospitales en México',
                        'Ayuda para Atención en el Extranjero',
                        'Estadía Hospitalaria Premium en México',
                        'Beneficio de Pago Directo',
                        'Suma asegurada',
                        'Sin Deducible ni Coaseguro',
                    ]}
                    appliesTo="Aplica para personas de los 0 a los 64 años."
                    onClick={() => { }}
                    />
                </Col>
            </Row>

            <Row id="plans" className='section6-row'>
                <Col>
                    <p className="montserrat-medium-manatee-20px section4-subtitle">Compara los planes de salud</p>
                    <p className="montserrat-bold-deep-cove-50px section4-title">Conoce y compara lo que te ofrecemos</p>
                    <p className="montserrat-normal-black-pearl-18px section1-text home-center-text">Cobertura y atención médica que se adaptan para proteger tu salud y la de tu familia</p>
                </Col>
            </Row>

            <Row>
                <Table striped responsive className='mons-font'>
                    <thead className='table-bold'>
                        <tr className='text-center'>
                            <th className='t-desc'>¿Qué te ofrecemos?</th>
                            <th>
                                <p className='th-vital'>Sincero Vital</p>
                                <p className='t-subtitulo t-display-big'>Planes desde</p>
                                <p className='t-subtitulo t-display-small'>Planes</p>
                                <p className='t-subtitulo t-display-small'>desde</p>
                                <p className='t-price'>$4,849.00 MXN</p>
                            </th>
                            <th>
                                <p className='th-flex'>Sincero Flex</p>
                                <p className='t-subtitulo t-display-big'>Planes desde</p>
                                <p className='t-subtitulo t-display-small'>Planes</p>
                                <p className='t-subtitulo t-display-small'>desde</p>
                                <p className='t-price'>$11,302.00 MXN</p>
                            </th>
                            <th>
                                <p className='th-plus'>Sincero Plus</p>
                                <p className='t-subtitulo t-display-big'>Planes desde</p>
                                <p className='t-subtitulo t-display-small'>Planes</p>
                                <p className='t-subtitulo t-display-small'>desde</p>
                                <p className='t-price'>$20,982.00 MXN</p>
                            </th>
                            <th>
                                <p className='th-total'>Sincero Total</p>
                                <p className='t-subtitulo t-display-big'>Planes desde</p>
                                <p className='t-subtitulo t-display-small'>Planes</p>
                                <p className='t-subtitulo t-display-small'>desde</p>
                                <p className='t-price'>$57,434.00 MXN</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        <tr>
                            <td className='td-title'>Check up anual</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                        </tr>
                        <tr >
                            <td className='td-title'>Consultas de atención primaria</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                        </tr>
                        <tr>
                            <td className='td-title'>Orientación médica a distancia</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                        </tr>
                        <tr>
                            <td className='td-title'>Atención médica domiciliaria</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                        </tr>
                        <tr>
                            <td className='td-title'>Atención no programada</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                        </tr>
                        <tr>
                            <td className='td-title'>Estudios de laboratorio</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                        </tr>
                        <tr>
                            <td className='td-title'>Terapias físicas</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                        </tr>
                        <tr>
                            <td className='td-title'>Indemnización por maternidad</td>
                            <td>X</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                        </tr>
                        <tr>
                            <td className='td-title'>Hospitales selectos en México</td>
                            <td>X</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>X</td>
                        </tr>
                        <tr>
                            <td className='td-title'>Atención médica en el extranjero</td>
                            <td>X</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                        </tr>
                        <tr>
                            <td className='td-title'>Libre elección del médico</td>
                            <td>X</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                        </tr>
                        <tr>
                            <td className='td-title'>Suma asegurada</td>
                            <td>X</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                        </tr>
                        <tr>
                            <td className='td-title'>Reducción de coaseguro</td>
                            <td>X</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                        </tr>
                        <tr>
                            <td className='td-title'>Todos los hospitales en México</td>
                            <td>X</td>
                            <td>X</td>
                            <td>&#x2713;</td>
                            <td>&#x2713;</td>
                        </tr>
                        <tr>
                            <td className='td-title'>Hospitales selectos en el extranjero</td>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                            <td>&#x2713;</td>
                        </tr>
                        <tr>
                            <td className='td-title'>Estadía hospitalaria premium en México</td>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                            <td>&#x2713;</td>
                        </tr>
                        <tr>
                            <td className='td-title'>Beneficio de pago directo</td>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                            <td>&#x2713;</td>
                        </tr>
                        <tr>
                            <td className='td-title'>Sin deducible ni coaseguro</td>
                            <td>X</td>
                            <td>X</td>
                            <td>X</td>
                            <td>&#x2713;</td>
                        </tr>
                    </tbody>
                </Table>
            </Row>


        </Container>
    );
}