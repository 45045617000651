export const individualurls={
    context : "individual",
    home : "/personas",
    plans : "/personas/planes",
    faqs : "/personas/faqs",
    contact : "/personas/contacto",
    register : `${window.URL_APP}/#/`,
    linkedin : "https://www.linkedin.com/company/sinceromx/",
    whatsapp : `${window.URL_WHATSAPP}`,
    startSesion : `${window.URL_APP}/#/`
  }
export const enterpriseUrls = {
    context : "enterprises",
    home : "/",
    plans : "/#planes",
    faqs : "/#preguntas-frecuentes",
    contact : "/empresas/contacto",
    register : `/registro-empresas`,
    linkedin :"https://www.linkedin.com/company/sinceromx/",
    whatsapp : `https://api.whatsapp.com/send?phone=+525592218477&text=Hola%20sincero%20quiero%20informacion%20de%20los%20seguros%20empresariales`,
    startSesion : `${window.URL_APP}/#/`
}
export const individualroute = "/personas";
export const enterpriseroute = "/";