import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import './homepage.css';
import './home_enterprises.css'
import './grid-behavior.css'
import { Accordion, Button, Col, Container, Row, Image } from 'react-bootstrap';
import TestimonyCard from './components/testimony/TestimonyCard';
import EnterpriseType from './components/enterprise-type/EnterpriseType';
import { enterpriseUrls } from '../../components/Routes';
/* import background from "../../assets/images/circles.svg";
 */import img1 from "../../assets/images/home/people-images/homepage1-2.png";
import img2 from "../../assets/images/home/people-images/homepage2-2.png";
import img3 from "../../assets/images/home/people-images/homepage3.png";
/* enterprise-size */
import building from "../../assets/images/home/enterprise-type/building.svg";
import enterprisesize from "../../assets/images/home/enterprise-type/enterprisesize.svg";
import hospitality from "../../assets/images/home/enterprise-type/hospitality.svg";
import startups from "../../assets/images/home/enterprise-type/startups.svg";
import Benefit from './components/benefits/Benefit';
/* benefits */
import familyprotected from "../../assets/images/home/benefits/familiyprotected.svg";
import identityfeeling from "../../assets/images/home/benefits/identityfeeling.svg";
import motivation from "../../assets/images/home/benefits/motivation.svg";
import socialimage from "../../assets/images/home/benefits/socialimage.svg";
import taxesdeductible from "../../assets/images/home/benefits/taxesdeductible.svg"
import trustandsecurity from "../../assets/images/home/benefits/trustandsecurity.svg"
/* Plans */
import applan from "../../assets/images/home/plans/applan.svg";
import gMmplan from "../../assets/images/home/plans/gMmplan.svg";
import totalcoverageplan from "../../assets/images/home/plans/totalcoverage.svg";
import GMMplan from "../../assets/images/home/plans/GMM.svg";
/* Testimonies */
import testimony1 from "../../assets/images/home/testimonies-people/enterprises/testimony1.png" ;
import testimony2 from "../../assets/images/home/testimonies-people/enterprises/testimony2.png";
import EnterprisePlanAccordion from './components/enterprise_plan_card/EnterprisePlanAccordion';
import TestimonyParent from './components/testimony/TestimonyParent';
/* Register */
export default function HomeEnterprises() {
  let location = useLocation();
  let {hash} = location;
  useEffect(() => {
    if (hash.includes("preguntas-frecuentes"))
    {
      document.querySelector("#faqs-offcanvas").click();
    }
    else if (hash){
      let hashEl = document.querySelector(hash);
      hashEl.scrollIntoView();
    }
    return;
    
  }, []);
  return (
    <div className="home-page">
      <Container className="main-enterprises enterprise-home">
        {/* Info principal */}
        <Row className="wrapper-row">
          <Col className="wrapper">
            <div className="title">
              <p className="montserrat-medium-manatee-20px enterprises-section-subtitle-left blue-purple-color">
              Cuidado honesto sin pagar más
              </p>
              <p className="title-pictures">La forma más fácil de manejar el cuidado de la salud en tu empresa</p>
            </div>
            <div className="images-rep">
              <Image
                fluid={true}
                src={img1}
                alt="manera facil de manejar tus polizas"
              />
            </div>
            <div className="textandbutton">
              <p className="montserrat-normal-black-pearl-18px section-enterprises-text">
              Sincero revoluciona la forma de administrar los planes de gastos médicos en tu empresa:
              maneja desde una plataforma las necesidades de salud de tus empleados; fácil, rápido y sin complicaciones, con los mejores planes que se adaptan a tu inversión.

              </p>
              <a href={enterpriseUrls.contact} className="contact-btn">
                <Button className="ml-auto">
                  <span className="avenir-medium-white-16px">Contáctanos</span>
                </Button>
              </a>
            </div>
          </Col>
        </Row>
        
        <Row id ="enterprises-plans" style={{height:"8vh"}}>
        </Row>
        {/* Coberturas para empresas */}
        <Row>
          <p className="title-pictures-greater">Tenemos la cobertura de
          salud que tu   
          empresa necesita</p>
        </Row>
        <Row className="section1-row" xs={1} sm={1} lg={2} md={1} >
          <Col>
            <EnterpriseType
            enterprisetype={"Startups"}
            enterprisedescription = {`Si tu empresa es dinámica, en constante crecimiento, entonces Sincero es para ti. 
            Nos adaptamos a cualquiera que sea la estructura de tu compañia, cuidándote desde sus primeros pasos.`} 
            imageurl = {startups}
            imagealt = "Empresa iniciando"/>
          </Col>
          <Col>
            <EnterpriseType
            enterprisetype={"Todas las empresas"}
            enterprisedescription = {`En Sincero, todas las empresas son especiales, no importa de que tamaño seas,
             tenemos la mejor cobertura para cuidar pequeños y grandes equipos de empleados.
            `} 
            imageurl = {enterprisesize}
            imagealt = "Empresas de todos tamaños"/>
          </Col>
          <Col>
          <EnterpriseType
            enterprisetype={"Hospitalidad"}
            enterprisedescription = {`Nuestros planes para la industria de la hospitalidad abarcan cualquier 
            necesidad para hoteles, cadenas, restaurantes, cafeterías y hasta comida rápida.`} 
            imageurl = {hospitality}
            imagealt = "Empresas de hospitalidad"/>
          </Col>
          <Col>
          <EnterpriseType
            enterprisetype={"Construcción"}
            enterprisedescription = {`Si trabajas con un grupo de personas dentro de la industria de construcción,
             obras, logística o parking, tenemos planes que protegen a tu equipo de cualquier imprevisto.`} 
            imageurl = {building}
            imagealt = "Empresa de construccion"/>
          </Col>
        </Row>
        {/* Planes de salud */}
        <Row id ="planes" style={{height:"8vh"}}>
        </Row>
        <Row id="plans" className='customize'>
          <Col>
            <p className="montserrat-medium-manatee-20px enterprises-section-subtitle-center">Productos</p>
            <p className="montserrat-bold-deep-cove-50px section4-title mb-5">Descubre nuestros planes creados <br /> para ti</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Accordion as="div" flush /* defaultActiveKey="0"  */className="insurance-plan-accordion">
              <EnterprisePlanAccordion
                itemkey={0}
                upperColor="#1331FF"
                planAbreviation = "AP (Accidentes Personales)"
                planName={"Accidentes Personales(AP)"}
                initialPrice="$400 MXN"
                priceDescription="anuales por asegurado"
                description={"Nuestros planes de Accidentes Personales protegen a tu equipo de posibles percances durante sus actividades profesionales; con la flexibilidad de tiempo para integrar a nuevos miembros, adaptándose a las dinámicas de tu empresa.  "}
                features={[
                  {name: "Planes flexibles: ", desc:"Cubrir las emergencias de salud que deriven de accidentes en las actividades profesionales." },
                  {name: "Seguro Anual o temporales ", desc:"con periodos flexibles: 2, 3, 6 o 12 meses." },
                  {name: "Administración detallada ", desc:"para dar de alta o baja personal." },
                ]}
                coverages = {[
                  "Muerte accidental",
                  "Perdidas orgánicas",
                  "Reembolso Gastos Médicos por Accidente con libertad para elegir el hospital privado*",
                  "Reembolso de Gastos Funerarios por Accidente *",
                  "Orientación médica telefónica y videoconsultas"
                            ]}
                deductible="$100 mxn para gastos médicos"
                coinsurance="No aplica"
                minimumpeople="10 personas"
                planImage={applan}
                />  
              <EnterprisePlanAccordion
                itemkey={1}
                upperColor="#F6DB65"
                planAbreviation = "(GMm) Gastos Médicos Menores"
                planName={"Gastos Médicos Menores (GMm)"}
                initialPrice="$2,049 MXN"
                priceDescription="anuales por asegurado"
                description={"Cuida al capital humano de tu empresa con nuestros planes de prevención que integran orientación médica, especialistas, estudios clínicos, medicamentos, descuentos y diversos beneficios más."}
                features={[
                  {name: "Seguro de Gastos Médicos Menores ", desc:"que se enfoca en la prevención y gestion de riesgos de la salud, a través del control y cuidado de la salud en todas las etapas de la vida."},
                ]}
                coverages = {[
                  "Consultas ilimitadas de atención primaria sin costo en clínicas Planmed.",
                  "Orientación médica telefónica 24 horas para empleados",
                  "Consulta para medicina general y pediatría por videoconsulta",
                  "Descuento 5% en medicamentos de venta libre en farmacias seleccionadas",
                  "Reportes estadísticos del estado de salud de colaboradores",
                  "Referencia médica para medicina interna, nutrición y psicología",
                  "Compra y envío de medicamentos a domicilio con Farma Leal",
                  "Chequeo médico inicial",
                  "Servicio de ambulancia por emergencia",
                  "Estudios de laboratorio, terapias de rehabilitación sin costo en clínicas Planmed.*",
                  "Consultas ilimitadas en especialidades como ortopedia, nutrición, psicologia, urología, entre otras sin costo en clínicas Planmed.*"
                            ]}
                deductible="No aplica"
                coinsurance="No aplica"
                minimumpeople="5 personas"
                planImage={gMmplan}
                />  
              <EnterprisePlanAccordion
                itemkey={2}
                upperColor="#E84EA1"
                planAbreviation = "(GMM) Gastos Médicos Mayores "
                planName={"Gastos Médicos Mayores (GMM)"}
                initialPrice="$4,000 MXN"
                priceDescription="anuales por asegurado"
                description={"Nuestros Planes de GMM brindan la protección que tus colaboradores requieren, con coberturas flexibles que los protegen de los principales gastos hospitalarios, procedimientos y tratamientos médicos; adaptamos nuestras coberturas de acuerdo con las necesidades de tu empresa."}
                features={[
                  {name: "Seguro de Gastos Médicos Mayores ", desc:"que cubre por cada enfermedad o accidente la cantidad que resulte después de descontar el deducible y coaseguro respectivamente al monto procedente."}
                ]}
                coverages = {[
                  "Gastos de hospitalización con habitación privada",
                  "Honorarios médicos",
                  "Medicamentos dentro y fuera del hospital",
                  "Indemnización por maternidad",
                  "Cero deducible por accidente",
                  "Traslado en ambulancia terrestre o aérea",
                  "Asistencia Dental",
                  "Extensión de cobertura a familiares (cónyuge, hijos y padres)*",
                  "Asistencia Funeraria*",
                  "Consultas médicas de especialidad a precio especial y descuentos en laboratorios*",
                  "Asistencia en viajes*",
                  "Emergencia en el extranjero*"
                            ]}
                deductible="Desde $5,000 mxn"
                coinsurance="10%"
                minimumpeople="5 asegurados titulares (empleados)"
                insuredamount={{from:"$500,000 mxn",to:"$50,000,000 mxn"}}
                planImage={GMMplan}
                /> 
              <EnterprisePlanAccordion
                itemkey={3}
                upperColor="#21DAC4"
                planAbreviation = "Cobertura Total"
                planName={"Cobertura Total (GMM+GMm)"}
                initialPrice="$6,000 MXN"
                priceDescription="anuales por asegurado"
                description={"Brinda la cobertura más robusta a tu empresa con nuestro plan GMM & GMm empresarial, tus colaboradores están cubiertos de los gastos hospitalarios mayores, así como de un plan de prevención que los protegerá antes, durante y posterior a cualquier eventualidad."}
                features={[
                  {name: "Seguro de Gastos Médicos Menores ", desc:"que se enfoca en la prevención y gestión de riesgos de la salud, a través del control y cuidado de la salud en todas las etapas de la vida."},
                  {name: "Seguro de Gastos Médicos Mayores ", desc:"que cubre por cada enfermedad o accidente la cantidad que resulte después de descontar el deducible y coaseguro respectivamente al monto procedente."}
                ]}
                coverages = {[
                  "Todas las coberturas de Seguro de Gastos Médicos Mayores y Menores Colectivo"
                            ]}
                deductible="No aplica"
                coinsurance="No aplica"
                minimumpeople="5 personas"
                planImage={totalcoverageplan}
                /> 
            </Accordion>  

          </Col>
        </Row>
        <Row style={{textAlign:"center"}}>
        <Col>
        <a href={enterpriseUrls.contact}  className="contact-btn">
              <Button>
                <span className="avenir-medium-white-16px">Contáctanos</span>
              </Button>
        </a>
        </Col>
        </Row>

        {/* Beneficios */}
        <Row id ="benefits" style={{height:"8vh"}}>
        </Row>
        <Row className='section1-row'>
          <Col className='section1-col'>
            <p className="montserrat-medium-manatee-20px enterprises-section-subtitle-center">Beneficios</p>
            <p className="montserrat-bold-deep-cove-50px section4-title">Sabemos lo que tu empresa <span className="blue-purple-color2">necesita</span></p>
            <p className="montserrat-medium-manatee-20px enterprises-section-subtitle-center">precención sincera</p>
          </Col>
        </Row>
        <Row xs={1} sm={1} md={2} lg={3}>
            <Benefit
              benefitdescription={"Tus empleados tendrán confianza y seguridad."}
              imagealt="confianza y seguridad"
              imageurl={trustandsecurity}
            />
            <Benefit
              benefitdescription={"Se fortalecerá el sentido de identidad y permanencia."}
              imagealt="sentido de identidad"
              imageurl={identityfeeling}
            />
            <Benefit
              benefitdescription={"Motiva a tus empleados y reduce la rotación de personal."}
              imagealt="motivacion"
              imageurl={motivation}
            />
            <Benefit
              benefitdescription={"Tu empresa transmitirá una imagen de conciencia social."}
              imagealt="conciencia social"
              imageurl={socialimage}
            />
            <Benefit
              benefitdescription={`Planes deducibles de impuestos si el beneficio se otorga de
               manera general a todos los empleados de tu empresa y la empresa paga la totalidad del Seguro.`}
              imagealt="deducible de impuestos"
              imageurl={taxesdeductible}
            />
            <Benefit
              benefitdescription={`Brinda tranquilidad al saber que las familias de tus empleados están protegidas.`}
              imagealt="tranquilidad"
              imageurl={familyprotected}
            />

        </Row>

        {/* Cuida de su bienestar sin descuidar el negocio */}
        <Row id ="employees-care" style={{height:"8vh"}}>
        </Row>
        <Row>
          <Col className="wrapper">
            <div className="title-right">
              <p className="title-pictures">
              Cuida de su bienestar, sin descuidar 
              el negocio
              </p>
            </div>

            <div className="images-rep-left care-emp-and-biz">
              <Image
                fluid={true}
                src={img2}
                loading="lazy"
                alt="Sincero explicacion"
              />
            </div>
            <div className="textandbutton-right">
              <p className="montserrat-normal-black-pearl-18px section-enterprises-text">
                Sabemos lo importante que es para ti el capital humano de tu empresa. Con Sincero, los cuidas a ellos sin sacrificar tu negocio. 
                Invierte en planes que se adapten a tu esquema empresarial, protegiendo a tu equipo hoy, para lograr más mañana.
              </p>
              <a href={enterpriseUrls.contact}  className="contact-btn sincero-light-blue-background">
                <Button className="ml-auto">
                  <span className="avenir-medium-white-16px">Contáctanos</span>
                </Button>
              </a>
            </div>
          </Col>
        </Row>

        {/* Testimonios */}
        <Row id ="testimonal-enterprises" style={{height:"8vh"}}>
        </Row>
        <Row>
        <Col className="testimonials-wrapper">
        <div className="testimonials-hero">
          <p className="montserrat-medium-manatee-20px enterprises-section-subtitle-left">Testimonios</p>
          <p className="title-pictures testimonials-title">
            Cada día más
            <br /><span className="enterprises-span-light-blue"> empresas </span>
            se protegen con<span className="enterprises-span-light-orange"> sincero</span></p>
          </div>
          <TestimonyParent
            testimonies = {
              [
              <TestimonyCard
              className= "testimony-card"
              key = {0}
              num = {"0"}
              image={testimony1}
              name="Miyelli Ordaz"
              occupation="CTO - WDNO"
              review = "Estoy feliz con Sincero porque ha sido muy fácil asegurarme a mí y a mi familia, vivo tranquila sabiendo que están protegidos ante cualquier imprevisto."
            />,
            <TestimonyCard
              key = {1}
              num = {"1"}
              image={testimony2}
              name="Pamela Trueba"
              occupation="HR - SuperEnterprise"
              review = "Trabajar con Sincero me dio un respiro, administrar toda la plantilla de gastos médicos de la empresa, ha sido práctico, sencillo y muy amigable."
            />
          ]
        }>
          
            
          </TestimonyParent>
        </Col>
        </Row>
        {/* Solidez tecnologica */}
        <Row id ="technology-stronghold" style={{height:"8vh"}}>
        </Row>
        <Row className='section1-row'>
          <Col className="wrapper">
            <div className="title technology">
              <p className="montserrat-medium-manatee-20px enterprises-section-subtitle-left">
              Solidez tecnológica 
              </p>
              <p className="title-pictures">Somos expertos con la tecnología, pero tenemos un acompañamiento humano</p>
            </div>
            <div className="images-rep">
              <Image
                fluid={true}
                src={img3}
                loading="lazy"
                alt="manera facil de manejar tus polizas"
              />
            </div>
            <div className="textandbutton">
              <p className="montserrat-normal-black-pearl-18px section-enterprises-text">
                Nunca te dejaremos solo: nuestra robusta tecnología lleva en el alma lo que más cuidamos, el trato humano. 
                Por eso siempre recibirás atención cálida y personal, en los momentos que más lo necesitas.
              </p>
              <a href={enterpriseUrls.contact}  className="contact-btn sincero-light-orange-background">
                <Button className="ml-auto">
                  <span className="avenir-medium-white-16px">Contrata ahora</span>
                </Button>
              </a>
            </div>
          </Col>
        </Row>
        <Row id ="preguntas-frecuentes">
        </Row>
        {/* Preguntas frecuentes */}
        <Row id="faq-container" className="customize" style={{marginBottom: '25px'}}>
          <Col>
            <h2 className="montserrat-bold-deep-cove-50px faq-header">Preguntas frecuentes</h2>
            <Accordion flush defaultActiveKey="2">
              <Accordion.Item eventKey="0">
                <Accordion.Header><span className='home-accordion-header'>¿Qué es deducible y coaseguro?</span></Accordion.Header>
                <Accordion.Body><span className='home-accordion-text'>
                  <b>Deducible </b>
                  es la participación del asegurado en la pérdida ocasionada por el siniestro antes de que el seguro médico comience a pagar. Por lo general, los seguros con deducibles más altos tienen primas más bajas.
                  <br />
                  <br />
                  <b>Coaseguro </b>
                  es el porcentaje que corresponde pagar al asegurado calculado sobre la diferencia de la pérdida ocasionada por el siniestro menos el deducible ya pagado.
                  </span>
                  </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header><span className='home-accordion-header'>Si ya estoy enfermo o necesito atención médica, ¿Puedo contratar el seguro?</span></Accordion.Header>
                <Accordion.Body><span className='home-accordion-text'>Generalmente si los padecimientos son de alto riesgo o muy costosos no es posible, sin embargo, en el caso de enfermedades que no representan un riesgo alto, la aseguradora puede aceptarla bajo ciertos parámetros y este tipo de aceptación conlleva un costo adicional a la prima conocido como extraprima. Por otro lado, en algunos casos se puede adquirir el seguro excluyendo la enfermedad en cuestión y sus complicaciones.</span></Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                <span className='home-accordion-header'>Si ya tengo seguro por una institución pública (IMSS, ISSSTE, etc.) ¿Aún puedo contratar un seguro de gastos médicos mayores?</span>
                </Accordion.Header>
                <Accordion.Body>
                <span className='home-accordion-text'>Claro que es posible y además es recomendable, ya que la rapidez en la atención en un hospital particular es en muchas ocasiones mucho mayor, ya que la demanda en hospitales públicos puede llevar a una saturación en los servicios.</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header><span className='home-accordion-header'>¿Cómo saber las coberturas de mi seguro de gastos médicos mayores?</span></Accordion.Header>
                <Accordion.Body><span className='home-accordion-text'>Es necesario siempre consultar la póliza y las condiciones generales del seguro. Además, es importante tener cercanía con el agente o bróker para cualquier duda.</span></Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header><span className='home-accordion-header'>¿Por qué cada año incrementa el costo de mi seguro?</span></Accordion.Header>
                <Accordion.Body><span className='home-accordion-text'>La aseguradora evalúa el riesgo año con año, el cual se ve incrementado por factores como la edad, siniestralidad del ramo, inflación nacional y del sector asegurador, ajustes en los servicios médicos de salud y el total de gastos cubiertos de todos los asegurados de la compañía.</span></Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header><span className='home-accordion-header'>¿Puedo pagar mi seguro mensualmente</span></Accordion.Header>
                <Accordion.Body><span className='home-accordion-text'>La periodicidad del pago del seguro puede ser mensual, trimestral, semestral o anual.</span></Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header><span className='home-accordion-header'>¿Cuál es la vigencia de mi seguro?</span></Accordion.Header>
                <Accordion.Body><span className='home-accordion-text'>Un año ya sea que pagues las primas de forma mensual, trimestral, semestral o anual. Al terminar el año, la póliza se debe renovar.</span></Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div >
  );
}

