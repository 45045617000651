import { useState, useRef, useEffect } from 'react';
import { Button, Col, Container, Row, Form } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import "./form.css"

function FormContact() {
  useEffect(()=>{      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    },[]) 
  const form = useRef();
  const [sendOk, setSendOk] = useState('');
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [errorEmail, setErrorEmail] = useState('');
  // eslint-disable-next-line
  const [openDialog, setOpenDialog] = useState(false);
// eslint-disable-next-line
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    const userName = document.getElementById('user_name').value;
    const userEmail = document.getElementById('user_email').value;
    const comment = document.getElementById('user_comments').value;
    // eslint-disable-next-line
    const options = document.getElementsByName('user_product').value;

    emailjs.sendForm(`${window.MAIL_SERVICE_ID}`, `${window.MAIL_CONTACT_TEMPLATE_ID}`, form.current, `${window.MAIL_USER_ID}`)
      .then((result) => {
        console.log(result.text);
        setSendOk(result.text);
        userName.value = '';
        userEmail.value = '';
        comment.value = '';
        setLoading(false);
        setOpenDialog(true);
        setErrorEmail(null)
      }, (error) => {
        setSendOk(null);
        setErrorEmail(error)
      });
  };


  return (
    <Container>
      <Form id="contactanos" className="contact-form" ref={form} onSubmit={sendEmail}>
        <Row className="personal-info" xs={{ cols: 1 }} md={{ cols: 2 }} lg={{ cols: 2 }}>
          <Col>
            <Form.Group className="mb-3" controlId="user_name">
              <Form.Label>*Nombre completo</Form.Label>
              <Form.Control required type="text" placeholder="Nombre completo" name="user_name" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="user_email">
              <Form.Label>*Correo electrónico</Form.Label>
              <Form.Control required  type="email" placeholder="Correo Electronico" name="user_email" />
            </Form.Group>
          </Col>
        </Row>


        <Row key={`inline-"radio"`} className="mb-3 sincero-products">
          <Col>
            <Form.Label id="interested-products-label">*Producto sincero de interes</Form.Label>
          </Col>
          <Col>
            <Form.Group >
              <Form.Check
                required 
                inline
                label="Vital"
                value="vital"
                type="radio"
                name="user_product"
              />
              <Form.Check
              required 
                inline
                label="Flex"
                value="flex"
                type="radio"
                name="user_product"
              />
              <Form.Check
              required 
                inline
                label="Plus"
                value="plus"
                type="radio"
                name="user_product"
              />
              <Form.Check
              required 
                inline
                label="Total"
                value="total"
                type="radio"
                name="user_product"
              />
            </Form.Group>

          </Col>
        </Row>
        <Form.Group className="mb-3 row" controlId="user_comments">
          <Form.Control as="textarea" rows={6} placeholder="Escribe tu comentario aquí (Opcional)" name="user_comments" />
        </Form.Group>
        <Row>
          <Col>
            <Button className="submit-button" type="submit">
            Enviar
            </Button>
          </Col>
        </Row>
      </Form>
      {errorEmail && (
        <div>
          <br/>
          <h4>
            Lo sentimos, ha ocurrido un error durante el envió de mensaje, por favor, intente más tarde.
          </h4>
        </div>
      )}
      {sendOk && (
        <h4>
          <br/>
          Tu mensaje ha sido enviado con éxito, te contactaremos a la brevedad.
        </h4>
      )}
    </Container>

  );
}

export default FormContact;