import { useState } from "react";
import {Accordion, Card, Col, Container, Row } from "react-bootstrap";
import './testimony-card.css';


export default function TestimonyCard(props) {
  const [widthSize, setWidthSize] = useState(window.innerWidth);
  window.addEventListener("resize", (e) =>setWidthSize(window.innerWidth))

  return (widthSize>=576 || props.notaccordion)  
  ?<Card className="testimony-card">
      <Container>
        <Row xs={2} sm={2} md={2} lg={2}>
          <Col xs="auto" sm={12} md={4} lg={3}>
            <img src={props.image} alt="Persona" />
          </Col>
          <Col xl="auto">
            <Card.Title>{props.name}</Card.Title>
            <Card.Subtitle className="review-card-styles">{props.occupation}</Card.Subtitle>
          </Col>
        </Row>
      </Container>
      <Card.Body className="review-card-styles">{props.review}</Card.Body>
  </Card>
  :
  <Accordion.Item eventKey={props.num}>
    <Accordion.Header>
    <Container>
        <Row xs={2} sm={2} md={2} lg={2}>
          <Col xs="auto" sm="auto" md={4} lg={2}>
            <img src={props.image} alt={`$ imagen de {props.name}`} />
          </Col>
          <Col xl="auto">
            <div className="testimony-name">{props.name}</div>
            <div className="testimony-position">{props.occupation}</div>
          </Col>
        </Row>
      </Container>
    </Accordion.Header>
    <Accordion.Body className="review-card-styles">{props.review}
    </Accordion.Body>
  </Accordion.Item>
}