import { Col, Row, Image } from "react-bootstrap";
import "./EnterpriseType.css"
export default function EnterpriseType({enterprisetype, enterprisedescription, imageurl, imagealt})
{
    return(
        <Row /* xs={1} sm={1} lg={2} md={2}  */className="enterprise">
            <Col xs={12} sm={5} >
                <Image className="enterprise-representative-icon"  src = {imageurl} alt ={imagealt?imagealt:""} ></Image>
            </Col>
            <Col xs={12} sm={7}>
                <span className="enterprise-coverage-title">{enterprisetype}</span>
                <title>{enterprisetype}</title>
                <br />
                <p className="enterprise-coverage-normal">{enterprisedescription}</p>
            </Col>
        </Row>
    );
}

