import { Row,Col } from "react-bootstrap";
import EnterpriseContactForm from "../../components/forms/EnterpriseContactForm";
import "./enterprise-contact.css"
export default function EnterpriseContactFormSection(){

  return (
    <EnterpriseContactForm
      titleSection={
      <Row className="contact-enterprises-title" xs={{cols:1}}>
        <Col className="bigger">Nos encanta saber de ti,</Col>
        <Col className="smaller">dejanos tus datos y nos podremos en contacto contigo 👋</Col>
      </Row>}
    
    />
  )
}

