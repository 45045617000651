import { useState } from "react";
import "./EnterprisePlanAccordion.css"
import { Accordion, Col,Row} from "react-bootstrap";
export default function EnterprisePlanAccordion(
    {planName, planImage, upperColor, initialPrice,description, features, coverages, deductible, coinsurance, minimumpeople, itemkey, planAbreviation, priceDescription, insuredamount=null })
{
    const [showMore, setShowMore] = useState(false);
    return(
            <Accordion.Item as="div"style={{
                boxShadow:"0px 3px 12px rgba(17, 60, 125, 0.05)",
                borderTop:"0.8vh solid", borderTopColor:upperColor,
                 borderTopLeftRadius:"1.5%", borderTopRightRadius:"1.5%"}} eventKey={itemkey.toString()} className="enterprise-plan-item-accordion" >
                <Accordion.Header as={"div"} className="customized-accordion-item headerplan">
                    <div className="enterprise-resume-header">
                    <div className="enterprise-plan-header">
                        <div>
                            <img src={planImage} className="accordion-image-plan" alt="" />
                        </div>
                        <div style={{marginLeft:"1rem"}} >
                            <span className="accordion-plan-title desktop">{planName}</span> <span className="accordion-plan-title mobile">{planAbreviation}</span> 
                            <br /> 
                            <span className="accordion-desde">Desde<span className="accordion-initial-price">{""+initialPrice+" "}<span className="desktop">{priceDescription}</span></span></span>
                        </div>
                    </div>
                    <div>
                        <br />
                        <p className="accordion-initial-price mobile">{planName}</p>
                        <p className="plan-description">
                        {description}
                        </p>
                    </div>
                    <div className="per-person mobile">
                        <p>#Costo anual por asegurado</p>
                        <div className="know-more"> Conocer más <span className="sub-button"></span></div>
                    </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <hr />
                    <Row>
                        <Col>
                        <ul>
                            {features.map((f, index) => <li key={index}><b> {f.name}</b>{f.desc}</li>)}
                        </ul>
                        </Col>
                    </Row>
                    <hr />
                    <Row xs={1} sm={1} md={2} className="full-features">
                        <Col>
                            <b>Coberturas</b>
                            <ul>
                                    {coverages.map((c, index) => <li key={index}>{c}</li>)}
                            </ul>
                        </Col>
                        <Col>
                            <p>
                                <b>Deducible</b>
                            </p> 
                            <ul>
                                <li>{deductible}</li>
                            </ul>
                            <p>
                                <b>Coaseguro</b>
                                <br />
                            </p> 
                            <ul>
                                <li>{coinsurance}</li>
                            </ul>
                            <p>
                                <b>Número minímo de asegurados</b>
                            </p> 
                            <ul>
                                <li>{minimumpeople}</li>
                            </ul>
                            
                            {(insuredamount)?<>                                
                            <p>
                                <b>Suma Asegurada</b>
                                <br />
                                </p> 
                                <ul>
                                    {(insuredamount.from)
                                        ? <li>{`Desde `}{insuredamount.from}</li> :<></>}
                                    {
                                    (insuredamount.to)
                                        ?<li>{`hasta `}{insuredamount.to}</li>
                                        :<></>
                                    }
                                </ul>
                            </>
                            :<></>}

                            
                        </Col>  
                    </Row>
                    <div className="aditional-cost">
                        *Coberturas con costo adicional
                    </div>
                </Accordion.Body>
            </Accordion.Item>
    );





}