import { useEffect } from 'react';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import './faqs.css';

export default function Faqs(){
    useEffect(()=>{      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    },[]) 

    return(
        <Container style={{marginTop: "17vh", marginBottom: "7vh"}}>
            <Row id="faqs" style={{marginBottom: '25px'}} className="customize">
            <Col>
                <h2 className="montserrat-bold-deep-cove-40px" style={{marginBottom:"50px"}}>Preguntas frecuentes</h2>
                
                <Accordion flush defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><span className='home-accordion-header'>Si ya estoy enfermo o necesito atención médica, ¿puedo contratar el seguro?</span></Accordion.Header>
                    <Accordion.Body><span className='home-accordion-text'>Generalmente si los padecimientos son de alto riesgo o muy costosos no es posible, sin embargo, en el caso de enfermedades que no representan un riesgo alto, la aseguradora puede aceptarla bajo ciertos parámetros y este tipo de aceptación conlleva un costo adicional a la prima conocido como extraprima. Por otro lado, en algunos casos se puede adquirir el seguro excluyendo la enfermedad en cuestión y sus complicaciones.</span></Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                    <span className='home-accordion-header'>Si ya tengo seguro por una institución pública (IMSS, ISSSTE, etc.) ¿Aún puedo contratar un seguro de gastos médicos mayores?</span>
                    </Accordion.Header>
                    <Accordion.Body>
                    <span className='home-accordion-text'>Claro que es posible y además es recomendable, ya que la rapidez en la atención en un hospital particular es en muchas ocasiones mucho mayor, ya que la demanda en hospitales públicos puede llevar a una saturación en los servicios.</span>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><span className='home-accordion-header'>¿Cómo saber las coberturas de mi seguro de gastos médicos mayores?</span></Accordion.Header>
                    <Accordion.Body><span className='home-accordion-text'>Es necesario siempre consultar la póliza y las condiciones generales del seguro. Además, es importante tener cercanía con el agente o bróker para cualquier duda.</span></Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header><span className='home-accordion-header'>¿Por qué cada año incrementa el costo de mi seguro?</span></Accordion.Header>
                    <Accordion.Body><span className='home-accordion-text'>La aseguradora evalúa el riesgo año con año, el cual se ve incrementado por factores como la edad, siniestralidad del ramo, inflación nacional y del sector asegurador, ajustes en los servicios médicos de salud y el total de gastos cubiertos de todos los asegurados de la compañía.</span></Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header><span className='home-accordion-header'>¿Puedo pagar mi seguro mensualmente?</span></Accordion.Header>
                    <Accordion.Body><span className='home-accordion-text'>La periodicidad de pago del seguro puede ser mensual, trimestral, semestral o anual.</span></Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header><span className='home-accordion-header'>¿Cuál es la vigencia de mi seguro?</span></Accordion.Header>
                    <Accordion.Body><span className='home-accordion-text'>Un año, ya sea que pagues las primas de forma mensual, trimestral, semestral o anual. Al terminar el año, la póliza se debe renovar.</span></Accordion.Body>
                </Accordion.Item>
                </Accordion>
            </Col>
            </Row>
        </Container>
    );
}