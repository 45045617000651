import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css';
import './styles/styleguide.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import HomePage from './pages/home/HomePage';
import NotFoundPage from './pages/not-found/NotFoundPage';
import Plans from './pages/plans/plans';
import Faqs from './pages/faqs/faqs';
import Contact from './pages/contact/components/contact';
import CustomNavbar from './components/navbar/CustomNavbar';
import Footer from './components/footer/Footer';
import EnterpriseContactFormSection from './pages/enterprises-contact/enterprise-contact';
import HomeEnterprises from './pages/home/homepage_enterprises';
import GMMPlan from './pages/enterprises-plans/MajorMedicalPlan';
import EnterpriseRegister from './pages/enterprise-register/EnterpriseRegister';
//enterprises or individual
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(  
  <React.StrictMode>
    <BrowserRouter >
      <HelmetProvider>
        <Helmet>
          <title>Sincero</title>
        </Helmet>
        <CustomNavbar />
        <Routes>
          <Route path="/" element={<HomeEnterprises></HomeEnterprises>}></Route>
          <Route path="/personas" element={<HomePage />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="personas/contacto" element={<Contact />} />
          <Route path="personas/planes" element={<Plans />} />
          <Route path="personas/faqs" element={<Faqs />} />
          <Route path="/registro-empresas" element={<EnterpriseRegister />} />
          <Route path="/empresas/contacto" element={<EnterpriseContactFormSection />} /> 
          <Route path="/homeemp" element={<HomeEnterprises />} />
          <Route path="/planes/gastos-mayores" element={<GMMPlan/>}/>
        </Routes>
        <Footer />
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
