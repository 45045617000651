import './homepage.css';
import { Accordion, Button, Col, Container, Row } from 'react-bootstrap';
import PlanCard from './components/plan-card/PlanCard';
import TestimonyCard from './components/testimony/TestimonyCard';
import testimony1 from "../../assets/images/home/testimonies-people/individual/testimony1.png" ;
import testimony2 from "../../assets/images/home/testimonies-people/individual/testimony2.png";

export default function HomePage() {

  return (
    <div className="home-page">
      <Container className="main-content">
        {/* Info principal */}
        <Row className='section1-row'>
          <Col className='section1-col'>
            <p className="montserrat-medium-manatee-20px section1-subtitle">Olvida todo sobre cobertura médica</p>
            <p className="montserrat-bold-tangaroa-50px section1-title"><span className='section1-textspan1'>Tecnología</span> que cuida de tu salud de <span className='section1-textspan1'>manera</span> <span className='section1-textspan2'>sincera</span></p>
            <img
              className="mobile-only section1-image"
              src="https://anima-uploads.s3.amazonaws.com/projects/62f3fa4227ede0977a7b5a2b/releases/62f400c6e2b6fa3dc59b6317/img/ilustracion-01-1@1.png"
              alt="Sincero explicacion"
            />
            <p className="montserrat-normal-black-pearl-18px section1-text">Tenemos el plan de gastos médicos ideal para ti, con las mejores condiciones que se adaptan a tus necesidades.</p>
            <a href="#plans ">
              <Button className="custom-btn-blue section1-button ml-auto">
                <span className="avenir-medium-white-16px">Conocer planes</span>
              </Button>
            </a>

          </Col>
          <Col xs={false} sm="6">
            <img
              className="desktop-only image100"
              src="https://anima-uploads.s3.amazonaws.com/projects/62f3fa4227ede0977a7b5a2b/releases/62f400c6e2b6fa3dc59b6317/img/ilustracion-01-1@1.png"
              alt="Sincero explicacion"
            />
          </Col>
        </Row>
        <Row className='section2-row'>
          <Col xs="12" sm="6">
            <div className="content-01">
              <div className="overlap-group6">
                <img
                  className="section2-circle"
                  src="https://anima-uploads.s3.amazonaws.com/projects/62f3fa4227ede0977a7b5a2b/releases/62f400c6e2b6fa3dc59b6317/img/vector-2@2x.png"
                  alt="People"
                />
                <img
                  className="image100 section2-image"
                  src="https://anima-uploads.s3.amazonaws.com/projects/62f3fa4227ede0977a7b5a2b/releases/62f400c6e2b6fa3dc59b6317/img/rectangle-21-1@2x.png"
                  alt="Rectangle"
                />
              </div>
            </div>
          </Col>
          <Col className='section2-col'>
            <p className="montserrat-medium-manatee-20px section2-subtitle">Tranquilidad para tu familia en caso de emergencias</p>
            <p className="montserrat-bold-tangaroa-50px section2-title">Sabemos tus prioridades: <span className='secion2-textspan1'>tu salud y la de los tuyos</span></p>
            <ul className="decorated-list">
              <li className="montserrat-normal-black-pearl-18px section2-text">Cotiza tu seguro de salud en menos de un minuto.</li>
              <li className="montserrat-normal-black-pearl-18px section2-text">Envianos tus documentos para iniciar el registro.</li>
              <li className="montserrat-normal-black-pearl-18px section2-text">¡Listo! La activación es de manera inmediata.</li>
            </ul>
            <a  href={`${window.URL_APP}/#/`}>
              <Button variant="danger" className="custom-btn-orange">
                <span className="montserrat-medium-white-16px">
                  Registrarme
                </span>
              </Button>
            </a>
          </Col>
        </Row>
        <Row className='section3-row'>
          <Col className='section3-col'>
            <p className="montserrat-medium-manatee-20px section3-subtitle">Sin letras pequeñas</p>
            <p className="montserrat-bold-tangaroa-50px section3-title">Fácilitamos la compra de tu seguro de salud</p>
            <img
              className="mobile-only image100"
              src="https://anima-uploads.s3.amazonaws.com/projects/62f3fa4227ede0977a7b5a2b/releases/62f400c6e2b6fa3dc59b6317/img/ilustraciones-whatsapp-1@2x.png"
              alt="whatsapp"
            />
            <ul className="decorated-list variant">
              <li className="montserrat-normal-black-pearl-18px section3-text extra-margin-bottom">¡Solo la verdad! Te ofrecemos primas de seguros confiables, adaptadas a tus necesidades.</li>
              <li className="montserrat-normal-black-pearl-18px section3-text extra-margin-bottom">Envía tus datos personales por WhatsApp y obtén un presupuesto, ¡así de fácil!</li>
            </ul>
            <a  href={`${window.URL_WHATSAPP}/#/`}>
              <Button variant="info" className="custom-btn-light-blue section3-button">
                <span className="avenir-medium-white-16px">Ir a Whatsapp</span>
              </Button>
            </a>
          </Col>
          <Col xs={false} sm="6">
            <img
              className="desktop-only image100"
              src="https://anima-uploads.s3.amazonaws.com/projects/62f3fa4227ede0977a7b5a2b/releases/62f400c6e2b6fa3dc59b6317/img/ilustraciones-whatsapp-1@2x.png"
              alt="whatsapp"
            />
          </Col>
        </Row>

        {/* Beneficios */}
        <Row className="section4-row">
          <Col className='section4-col'>
            <p className="montserrat-medium-manatee-20px section4-subtitle">Beneficios</p>
            <p className="montserrat-bold-deep-cove-50px section4-title">Sabemos lo que <span className='section4-textspan'>necesitas</span></p>
            <p className="montserrat-normal-black-pearl-18px section4-text">prevención sincera</p>
          </Col>
        </Row>
        <Row className='section4-col section4-margintop'>
          <Col sm="12" md="4" >
            <img
              src="https://anima-uploads.s3.amazonaws.com/projects/62f3fa4227ede0977a7b5a2b/releases/62f400c6e2b6fa3dc59b6317/img/fi-dollar-sign-1@2x.png"
              alt="Accesible"
            />
            <p className="montserrat-bold-tangaroa-24px section4-margintop">Accesible</p>
            <p className="montserrat-normal-black-pearl-18px section4-margintop">Cuida tu salud con planes que inician desde 4 mil pesos anuales</p>
          </Col>
          <Col sm="12" md="4">
            <img
              className="handshake"
              src="https://anima-uploads.s3.amazonaws.com/projects/62f3fa4227ede0977a7b5a2b/releases/62f400c6e2b6fa3dc59b6317/img/handshake-1@2x.png"
              alt="Confiable"
            />
            <p className="montserrat-bold-tangaroa-24px section4-margintop">Confiable</p>
            <p className="montserrat-normal-black-pearl-18px section4-margintop">Sin letras chiquitas ni cláusulas complicadas</p>

          </Col>
          <Col sm="12" md="4">
            <img
              className="hand-pointing"
              src="https://anima-uploads.s3.amazonaws.com/projects/62f3fa4227ede0977a7b5a2b/releases/62f400c6e2b6fa3dc59b6317/img/handpointing-1@2x.png"
              alt="Fácil"
            />
            <p className="montserrat-bold-tangaroa-24px section4-margintop">Fácil</p>
            <p className="montserrat-normal-black-pearl-18px section4-margintop">Administra todas tus pólizas de forma completamente digital</p>
          </Col>
        </Row>

        {/* More info */}
        <Row className='section5-row'>
          <Col sm={false} md="6">
            <img
              className="desktop-only section5-image"
              src="https://anima-uploads.s3.amazonaws.com/projects/62f3fa4227ede0977a7b5a2b/releases/62f400c6e2b6fa3dc59b6317/img/rectangle-20-1@2x.png"
              alt="Mujer con hija"
            />
          </Col>
          <Col sm="12" md="6">
            <p className="montserrat-medium-manatee-20px section5-subtitle">Como debe ser</p>
            <p className="montserrat-bold-deep-cove-32px section4-title section5-title">Cuida de tu salud de manera preventiva</p>
            <Row className='home-center-text justify-content-sm-center'>
              <Col xs="12" sm="6" lg="4" >
                <p className="montserrat-bold-deep-cove-80px">80%</p>
                <p className="montserrat-medium-tangaroa-18px">Menor tiempo para contratar</p>
              </Col>
              <Col xs="12" sm="6" lg="4">
                <p className="montserrat-bold-deep-cove-80px">20%</p>
                <p className="montserrat-medium-tangaroa-18px">Mejor acceso a hospitales y doctores</p>
              </Col>
              <Col xs="12" sm="6" lg="4">
                <p className="montserrat-bold-deep-cove-80px">30%</p>
                <p className="montserrat-medium-tangaroa-18px">Más barato</p>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Planes de salud */}
        <Row id="plans" className='section6-row'>
          <Col>
            <p className="montserrat-medium-manatee-20px section4-subtitle">Planes de salud</p>
            <p className="montserrat-bold-deep-cove-50px section4-title">Cobertura <span className='home-span-blue'>sincero</span></p>
            <p className="montserrat-normal-black-pearl-18px section1-text home-center-text">Cobertura y atención médica que se adaptan para proteger tu salud y la de tu familia</p>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6" lg="6" xl="3">
            <PlanCard
              icon="https://anima-uploads.s3.amazonaws.com/projects/62f3fa4227ede0977a7b5a2b/releases/62f400c6e2b6fa3dc59b6317/img/sincero-vital@1x.png"
              color="var(--royal-blue)"
              title="Sincero Vital"
              price={4849}
              includes={[
                'Check up anual',
                'Consultas con especialistas',
                'Orientación a distancia',
                'Ambulancia',
                'Estudios de laboratorio',
                'Terapia física',
              ]}
              appliesTo="Aplica para personas de todas las edades."
              onClick={() => {}}
            />
          </Col>
          <Col xs="12" md="6" lg="6" xl="3">
            <PlanCard
              icon="https://anima-uploads.s3.amazonaws.com/projects/62f3fa4227ede0977a7b5a2b/releases/62f400c6e2b6fa3dc59b6317/img/sincero-flex@1x.png"
              color="#c751ff"
              title="Sincero Flex"
              price={11302}
              includes={[
                'Hospitales Selectos en México',
                'Atención Médica en el Extranjero',
                'Eliges a tu Médico',
                'Indemnización por Maternidad',
                'Suma asegurada',
                'Reducción de Coaseguro',
              ]}
              appliesTo="Aplica para personas de los 0 a los 70 años."
              onClick={() => { }}
            />
          </Col>
          <Col xs="12" md="6" lg="6" xl="3">
            <PlanCard
              icon="https://anima-uploads.s3.amazonaws.com/projects/62f3fa4227ede0977a7b5a2b/releases/62f400c6e2b6fa3dc59b6317/img/sincero-plus@1x.png"
              color="var(--picton-blue)"
              title="Sincero Plus"
              price={20982}
              includes={[
                'Todos los hospitales en México',
                'Ayuda para Atención en el Extranjero',
                'Eliges a tu Médico',
                'Indemnización por Maternidad',
                'Suma asegurada',
                'Reducción de Coaseguro',
              ]}
              appliesTo="Aplica para personas de los 0 a los 70 años."
              onClick={() => { }}
            />
          </Col>
          <Col xs="12" md="6" lg="6" xl="3">
            <PlanCard
              icon="https://anima-uploads.s3.amazonaws.com/projects/62f3fa4227ede0977a7b5a2b/releases/62f400c6e2b6fa3dc59b6317/img/sincero-plus@1x.png"
              color="var(--persimmon)"
              title="Sincero Total"
              price={57434}
              includes={[
                'Todos los hospitales en México',
                'Ayuda para Atención en el Extranjero',
                'Estadía Hospitalaria Premium en México',
                'Beneficio de Pago Directo',
                'Suma asegurada',
                'Sin Deducible ni Coaseguro',
              ]}
              appliesTo="Aplica para personas de los 0 a los 64 años."
              onClick={() => { }}
            />
          </Col>
        </Row>

        {/* Testimonios */}
        <Row>
          <Col sm="12" md="6">
              <p className="montserrat-medium-manatee-20px">Testimonios</p>
          <p className="montserrat-bold-deep-cove-50px"><span className="span-light-blue">Cada día más</span> personas utilizan <span className="span-orange">sincero</span></p>
          </Col>
          <Col>
            <TestimonyCard
              notaccordion = {true}
              image={testimony1}
              name="José Chavero"
              occupation="Cliente Sincero"
              review="El proceso para contratar fue muy fácil para el seguro de mi hija, siempre había tenido dudas y preguntas que no me quedaban claras con otros brokers y aquí recibí toda la información de forma clara y concisa. Agradezco a Sincero, ahora estoy más tranquilo porque sé que mi hija está protegida."
            />
            <br />
            <TestimonyCard
              notaccordion = {true}
              image={testimony2}              
              name="María Luna"
              occupation="Cliente Sincero"
              review="Hace unas semanas tuve un problema con el hospital y les llamé directamente. El servicio, eficiencia y la atención son únicos. Me ayudaron directamente, se esforzaron por entender cómo podían mejorar el servicio y la atención al cliente es muy importante para ellos. Gracias a Sincero me siento segura y protegida."
            />
            <br />
          </Col>
        </Row>

        {/* Preguntas frecuentes */}
        <Row id="faq-container" style={{marginBottom: '25px'}}>
          <Col>
            <h2 className="montserrat-bold-deep-cove-50px">Preguntas frecuentes</h2>
            
            <Accordion className="customize" flush defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header><span className='home-accordion-header'>Si ya estoy enfermo o necesito atención médica, ¿puedo contratar el seguro?</span></Accordion.Header>
                <Accordion.Body><span className='home-accordion-text'>Generalmente si los padecimientos son de alto riesgo o muy costosos no es posible, sin embargo, en el caso de enfermedades que no representan un riesgo alto, la aseguradora puede aceptarla bajo ciertos parámetros y este tipo de aceptación conlleva un costo adicional a la prima conocido como extraprima. Por otro lado, en algunos casos se puede adquirir el seguro excluyendo la enfermedad en cuestión y sus complicaciones.</span></Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                <span className='home-accordion-header'>Si ya tengo seguro por una institución pública (IMSS, ISSSTE, etc.) ¿Aún puedo contratar un seguro de gastos médicos mayores?</span>
                </Accordion.Header>
                <Accordion.Body>
                <span className='home-accordion-text'>Claro que es posible y además es recomendable, ya que la rapidez en la atención en un hospital particular es en muchas ocasiones mucho mayor, ya que la demanda en hospitales públicos puede llevar a una saturación en los servicios.</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header><span className='home-accordion-header'>¿Cómo saber las coberturas de mi seguro de gastos médicos mayores?</span></Accordion.Header>
                <Accordion.Body><span className='home-accordion-text'>Es necesario siempre consultar la póliza y las condiciones generales del seguro. Además, es importante tener cercanía con el agente o bróker para cualquier duda.</span></Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header><span className='home-accordion-header'>¿Por qué cada año incrementa el costo de mi seguro?</span></Accordion.Header>
                <Accordion.Body><span className='home-accordion-text'>La aseguradora evalúa el riesgo año con año, el cual se ve incrementado por factores como la edad, siniestralidad del ramo, inflación nacional y del sector asegurador, ajustes en los servicios médicos de salud y el total de gastos cubiertos de todos los asegurados de la compañía.</span></Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header><span className='home-accordion-header'>¿Puedo pagar mi seguro mensualmente?</span></Accordion.Header>
                <Accordion.Body><span className='home-accordion-text'>La periodicidad de pago del seguro puede ser mensual, trimestral, semestral o anual.</span></Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header><span className='home-accordion-header'>¿Cuál es la vigencia de mi seguro?</span></Accordion.Header>
                <Accordion.Body><span className='home-accordion-text'>Un año, ya sea que pagues las primas de forma mensual, trimestral, semestral o anual. Al terminar el año, la póliza se debe renovar.</span></Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div >
  );
}

